import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "../../../utils/api";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Pic from "../../../assets/images/icons/user.png";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { useUserContext } from "../../../utils/hooks/UserContext";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';

const UserScheduleSessionComponent = () => {
    const { user } = useUserContext()

    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    console.log(user);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    return (
        <>
            <section className="p-5 session-schedule-user">
                <div className="row">
                    <div className="col-md-6 hdng_a text-center">
                        <h6>Schedule Session</h6>
                        <h2 className="mb-4">Book Your Time Slot</h2>
                        <div className="">
                            <div>
                                {user?.profile_pic && <img width={150} height={150} src={`${process.env.REACT_APP_USER_PROFILE_FOLDER_PATH}${user?.profile_pic}`} className="rounded-circle" alt="" />}
                                {!user?.profile_pic && <img width={150} height={150} src={Pic} className="rounded-circle" alt="" />}
                            </div>
                            <h3>{user?.name}</h3>
                            <p>{user?.interests}</p>
                            <span className="fs-18">30 Min one to one session</span>
                        </div>
                    </div>
                    <div className="col-md-6 bg-body-tertiary py-4">
                        <form>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="">
                                        <label className="form-label fs-14">Select a Date & Time</label>
                                        {/* <input type="date" data-date-inline-picker="true" className="form-control" /> */}
                                        <DatePicker
                                            selected={startDate}
                                            onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                                            selectsRange
                                            inline
                                        />
                                    </div>
                                    <div className="">
                                        <label className="form-label fs-14">Time Zone</label>
                                        <select className="form-control fs-14">
                                            <option>Indian Standard Time (+05:30)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="">
                                        <label className="form-label fs-14">Tuesday, July 16</label>
                                        <div>
                                            <input type="checkbox" className="d-none" id="eight" name="eight" value="08:00 am" />
                                            <label for="eight" className="check-box-btn"> 08:00 am</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="ten" name="ten" value="10:00 am" />
                                            <label for="ten" className="check-box-btn"> 10:00 am</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="three" name="three" value="03:00 pm" />
                                            <label for="three" className="check-box-btn"> 03:00 pm</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="four" name="four" value="04:00 pm" />
                                            <label for="four" className="check-box-btn active"> 04:00 pm</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="five" name="five" value="05:00 pm" />
                                            <label for="five" className="check-box-btn"> 05:00 pm</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="fs-14 btn btn-success btn-md w-100 mt-2">Confirm Slot</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserScheduleSessionComponent;
