import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Row,
  Tab
} from "react-bootstrap";

import Modal from "react-awesome-modal";
import { NavLink } from "react-router-dom";

import './Calendar.css';

const BookingComponent = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [events, setEvents] = useState([
    { date: '2024-08-20', name: 'Meeting', time: '10:00 AM' },
    { date: '2024-08-22', name: 'Conference', time: '02:00 PM' },
    // Add more events as needed
  ]);

  // Handle date change
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  // Filter events between start and end dates
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.date);
    return (
      (!startDate || eventDate >= new Date(startDate)) &&
      (!endDate || eventDate <= new Date(endDate))
    );
  });

  // Render the table
  const renderTable = () => {
    if (!startDate || !endDate) return <p>Select a date range to see events.</p>;

    return (
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Event Name</th>
            <th>Event Time</th>
          </tr>
        </thead>
        <tbody>
          {filteredEvents.map((event, index) => (
            <tr key={index}>
              <td>{event.date}</td>
              <td>{event.name}</td>
              <td>{event.time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <section className="coach_my_activity py-3">
      <div>
        <div className="date-selectors d-flex">
          <label>
            Start Date:
            <input type="date" value={startDate || ''} onChange={handleStartDateChange} />
          </label>
          <label className="ms-4">
            End Date:
            <input type="date" value={endDate || ''} onChange={handleEndDateChange} />
          </label>
        </div>
      </div>
      <div className="text-start hdng_a mb-3 d-flex justify-content-between align-items-center">
        <h2 className="m-0">My Booking</h2>
        <div className="dropdowns m-0">
          <select>
            <option>Dropdown 1</option>
            <option>Option 1</option>
            <option>Option 2</option>
          </select>
          <select>
            <option>Dropdown 2</option>
            <option>Option 1</option>
            <option>Option 2</option>
          </select>
          <select>
            <option>Dropdown 3</option>
            <option>Option 1</option>
            <option>Option 2</option>
          </select>
        </div>
      </div>
      <Container>
        <div className="booking-component">
          <div className="table-container">
            {renderTable()}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BookingComponent;
